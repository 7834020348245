import React from 'react';

import { Box } from '@mui/material';
import { palette, ProgressBar } from 'workmotion-design-system';

import { en } from 'localization';

const progressBarStyling = {
  progressBar: {},
  progressStatus: {
    color: palette.primary.genericViridian,
    fontWeight: 600,
  },
};

interface StepsProgressProps {
  currentStepNumber: number;
  totalStepsCount: number;
}

export const StepsProgress: React.FC<StepsProgressProps> = ({
  currentStepNumber,
  totalStepsCount,
}) => (
  <Box
    sx={{ padding: '2rem 2rem 0 2rem', display: 'flex', width: '100%' }}
    data-testid="step-progress"
  >
    <ProgressBar
      progress={currentStepNumber / totalStepsCount}
      progressStatus={en.offBoarding.steps.stepsProgress.stepsProgress(
        currentStepNumber,
        totalStepsCount
      )}
      styling={progressBarStyling}
    />
  </Box>
);
