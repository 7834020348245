import { useCallback, useEffect, useState } from 'react';

// eslint-disable-next-line import/named
import { AccessToken } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { addBreadcrumb, captureException } from '@sentry/react';

export function useAccessToken(): {
  accessToken: AccessToken;
  errorHandler: (error: Error) => void;
} {
  const { authState, oktaAuth } = useOktaAuth();

  const [accessToken, setAccessToken] = useState<AccessToken>(
    authState.accessToken as AccessToken
  );

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const initialTokenValue = (await oktaAuth.tokenManager.get(
          'accessToken'
        )) as AccessToken;

        addBreadcrumb({
          message: 'Token initialized: ',
          data: { token: initialTokenValue },
        });

        oktaAuth.tokenManager.on('renewed', (key, token, oldToken) => {
          if (key === 'accessToken') {
            addBreadcrumb({
              message: 'Token renewed: ',
              data: { token, oldToken },
            });

            setAccessToken(token as AccessToken);
          }
        });

        setAccessToken(initialTokenValue);
      } catch (e) {
        addBreadcrumb({ message: 'Handling error from getting access token' });
        captureException(e);
      }
    };

    getAccessToken();
  }, [oktaAuth.tokenManager]);

  /**
   * It should be an error handler for 401 and 403 requests.
   */
  const errorHandler = useCallback(
    e => {
      if (e && e.errorCode === 'login_required') {
        return oktaAuth.signOut();
      }

      captureException(e);
    },
    [oktaAuth]
  );

  return { accessToken, errorHandler };
}
