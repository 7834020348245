import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  compileRawSchema,
  fetchRawSchema,
  getOffboarding,
  getOffboardings,
  getSchema,
  getOffboardingFlows,
  SchemaRequest,
  getCalculationAvailableCountryCodes,
  calculateOffboardingCostManually,
  calculateOffboardingCostForTalent,
} from './offboarding-client';
import { Components } from './offboarding-contract';
import { useAccessToken } from '../../authentication/use-access-token';
import {
  AuthenticatedIdentity,
  useUserIdentity,
} from '../../authentication/use-user-identity';

export function useOffboardingSchema(countryCode?: string, flowType?: string) {
  const {
    accessToken: { accessToken },
  } = useAccessToken();

  const queryFn = useCallback(
    async () => getSchema(countryCode, flowType, accessToken),
    [countryCode, flowType, accessToken]
  );

  return useQuery({
    queryKey: ['offboarding-schema', { countryCode, flowType }],
    queryFn,
    enabled: countryCode != null && flowType != null,
  });
}

export function useTalentOffboardings(talentId?: string, flowType?: string) {
  const {
    accessToken: { accessToken },
  } = useAccessToken();

  const { companyId } = useUserIdentity() as AuthenticatedIdentity;

  const queryFn = useCallback(
    async () => getOffboardings(talentId, companyId, flowType, accessToken),
    [talentId, companyId, accessToken, flowType]
  );

  return useQuery({
    queryKey: ['talent-offboardings', talentId, flowType],
    queryFn,
    enabled: talentId != null && companyId != null && flowType !== null,
  });
}

export function useOffboarding(offboardingId: string) {
  const {
    accessToken: { accessToken },
  } = useAccessToken();

  const queryFn = useCallback(
    async () => getOffboarding(offboardingId, accessToken),
    [offboardingId, accessToken]
  );

  return useQuery({
    queryKey: ['offboarding', 'details', offboardingId],
    queryFn,
  });
}

export function useRawSchema(countryIsoCode: string, flowType: string) {
  const { accessToken } = useAccessToken();

  const queryFn = useCallback(
    async () =>
      fetchRawSchema(countryIsoCode, flowType, accessToken.accessToken),
    [countryIsoCode, flowType, accessToken.accessToken]
  );

  return useQuery({
    queryKey: ['offboarding-schema', { countryIsoCode, flowType, raw: true }],
    queryFn: queryFn,
    enabled: countryIsoCode != null,
  });
}

function calculateHash(str: string) {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;

  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
}

export function useCompiledSchema(rawSchema?: SchemaRequest) {
  const { accessToken } = useAccessToken();

  const queryFn = useCallback(
    async () => compileRawSchema(rawSchema, accessToken.accessToken),
    [accessToken.accessToken, rawSchema]
  );

  return useQuery<Components.Schemas.OffboardingSchemaDTO, AxiosError<unknown>>(
    {
      queryKey: [
        'offboarding-schema',
        {
          countryIsoCode: rawSchema?.countryIsoCode,
          compiled: true,
          version: calculateHash(JSON.stringify(rawSchema || {})),
        },
      ],
      queryFn,
      enabled: rawSchema != null,
    }
  );
}

export function useGetOffboardingFlows(talentId?: string) {
  const {
    accessToken: { accessToken },
  } = useAccessToken();

  const queryFn = useCallback(
    async () => getOffboardingFlows(talentId, accessToken),
    [talentId, accessToken]
  );

  return useQuery({
    queryKey: ['talent-offboardings-Flows', talentId],
    queryFn,
    enabled: !!talentId,
  });
}

export type OffboardingCostCalculation =
  Components.Schemas.OffboardingCostCalculationResponse;

export type ManualInputs = {
  countryIsoCode: string;
  employmentYears: number;
  annualSalary: number;
  currency: string;
  unusedPTO: number;
  noticePeriodMonths: number;
};

export type TalentInputs = {
  talentId: string;
};

export function useOffboardingCostCalculation(
  inputs: ManualInputs | TalentInputs
) {
  const {
    accessToken: { accessToken },
  } = useAccessToken();

  const queryFn = async () => {
    if (Object.keys(inputs).length === 1 && inputs.hasOwnProperty('talentId'))
      return calculateOffboardingCostForTalent(
        inputs as TalentInputs,
        accessToken
      );

    return calculateOffboardingCostManually(
      inputs as ManualInputs,
      accessToken
    );
  };

  return useQuery({
    queryKey: ['offboarding-cost', ...Object.values(inputs)],
    // We need to override the default error handler to not show the error when user's country is not supported
    onError: () => {},
    queryFn,
    enabled:
      (Object.values(inputs).every(value => value != null) &&
        Object.values(inputs).length >= 6) ||
      Object.values(inputs).length === 1,
  });
}

export function useCalculationAvailableCountryCodes() {
  const {
    accessToken: { accessToken },
  } = useAccessToken();

  const queryFn = useCallback(
    async () => getCalculationAvailableCountryCodes(accessToken),
    [accessToken]
  );

  return useQuery({
    queryKey: ['offboarding-cost', 'config'],
    queryFn,
  });
}
