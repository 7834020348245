import { useCallback } from 'react';

import { addBreadcrumb, captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAccessToken } from 'authentication/use-access-token';

import {
  saveStep,
  startOffboarding,
  updateOffboardingStatus,
} from './offboarding-client';
import { Components } from './offboarding-contract';

type ComponentRequest = Components.Schemas.ComponentRequest;
type OffboardingResponseDTO = Components.Schemas.OffboardingResponseDTO;

export const useStartOffboardingMutation = (
  talentId: string,
  flowType: string
) => {
  const {
    accessToken: { accessToken },
  } = useAccessToken();

  const mutationFn = useCallback(
    async () => startOffboarding(talentId, flowType, accessToken),
    [accessToken, flowType, talentId]
  );

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['active-offboarding']),
        queryClient.invalidateQueries(['talent-offboardings']),
      ]);
    },
  });
};

export const useSaveOffboardingStepMutation = (offboardingId: string) => {
  const {
    accessToken: { accessToken },
  } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async ({
      stepId,
      components,
    }: {
      stepId: string;
      components: ComponentRequest[];
    }) => saveStep(offboardingId, stepId, components, accessToken),
    [accessToken, offboardingId]
  );

  return useMutation<
    OffboardingResponseDTO,
    unknown,
    { stepId: string; components: ComponentRequest[] }
  >({
    mutationFn,
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries([
          'offboarding',
          'details',
          offboardingId,
        ]);

        addBreadcrumb({
          message: 'Offboarding with id: ' + offboardingId + ' is invalidated.',
        });
      } catch (e) {
        captureException(e);
      }
    },
  });
};

export const useUpdateOffboardingStatus = (offboardingId: string) => {
  const {
    accessToken: { accessToken },
  } = useAccessToken();

  const mutationFn = useCallback(
    async () => updateOffboardingStatus(offboardingId, accessToken),
    [accessToken, offboardingId]
  );

  const queryClient = useQueryClient();

  const { mutate } = useMutation<OffboardingResponseDTO>({
    mutationFn,
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries([
          'offboarding',
          'details',
          offboardingId,
        ]);

        addBreadcrumb({
          message: 'Offboarding with id: ' + offboardingId + ' is invalidated.',
        });
      } catch (e) {
        captureException(e);
      }
    },
  });

  return { mutate };
};
