/* eslint-disable react/jsx-newline */
import React from 'react';

import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

import { useOffboardingCostCalculation } from '../../../networking/offboarding/offboarding-requests';

const Card = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background-color: white;
  border-radius: 8px;
`;

export const OffboardingCost: React.FC<{
  talentId: string;
  talentName: string;
}> = ({ talentId, talentName }) => {
  const { data, isLoading, isSuccess } = useOffboardingCostCalculation({
    talentId,
  });

  if (isLoading || !isSuccess) return null;

  return (
    <Card>
      <Typography variant="h2" color={palette.primary.genericViridian}>
        Expected offboarding effort
      </Typography>

      <Box
        display="flex"
        flexDirection="row"
        width="90%"
        justifyContent="space-between"
      >
        <Typography variant="body1" fontWeight="bold">
          Cost: {data.cost.amount} {data.cost.currency}
        </Typography>

        <Typography variant="body1" fontWeight="bold">
          Time: {data.time.amount} {data.time.unit}
        </Typography>
      </Box>

      <Typography variant="body1">
        This is an estimate of cost and time associated with offboarding{' '}
        {talentName}. Get the full breakdown using our{' '}
        <Link
          to="/tools/offboarding-calculator"
          target="_blank"
          style={{ color: palette.primary.cerisePink }}
        >
          Offboarding Cost Calculator
        </Link>
      </Typography>
    </Card>
  );
};
