import React from 'react';

import { useOktaAuth } from '@okta/okta-react';
import { FeatureFlagsWrapper } from '@workmotion/feature-flags-library';
import { Navigate, Route } from 'react-router-dom';
import { Spinner } from 'workmotion-design-system';

import { Guide } from './guide';
import { Steps } from './steps';
import { useUserIdentity } from '../authentication/use-user-identity';
import { SentryRoutes } from '../tracking/routes';

const SchemaEditor = React.lazy(() => import('./schema-editor'));
const CalculatorPage = React.lazy(() => import('./calculator/calculator-page'));

export const AppRoutes: React.FC = () => {
  const { authState } = useOktaAuth();
  const identity = useUserIdentity();

  if (authState == null) {
    return <Spinner />;
  }

  if (!identity.isAuthenticated) {
    return (
      <SentryRoutes>
        <Route path="*" element={<Navigate to="/" replace />} />
      </SentryRoutes>
    );
  }

  return (
    <FeatureFlagsWrapper userInfo={identity.featureFlagsIdentity}>
      <SentryRoutes>
        <Route
          path="/offboarding/:applicationId"
          element={<Navigate to={'guide'} replace />}
        />

        <Route path="/offboarding/:applicationId/guide" element={<Guide />} />

        <Route path="/offboarding/:offboardingId/*" element={<Steps />} />

        <Route path={'/offboarding/schema-editor'} element={<SchemaEditor />} />

        <Route
          path="/tools/offboarding-calculator"
          element={<CalculatorPage />}
        />
      </SentryRoutes>
    </FeatureFlagsWrapper>
  );
};
