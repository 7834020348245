import React, { useCallback, useEffect } from 'react';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { addBreadcrumb, captureException } from '@sentry/react';
import { useNavigate } from 'react-router-dom';

const ISSUER = window.env.OKTA_ISSUER;
const CLIENT_ID = window.env.OKTA_CLIENT_ID;
const REDIRECT_URI = `${window.location.origin}/login/callback`;

function initializeOktaInstance() {
  return new OktaAuth({
    issuer: ISSUER,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    postLogoutRedirectUri: `${window.location.origin}/login`,
    tokenManager: { autoRenew: true, expireEarlySeconds: 4.5 * 60 },
    scopes: ['email', 'openid', 'offline_access', 'profile'],
  });
}

const oktaAuth = initializeOktaInstance();

export const AuthenticationContext: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();

  const restoreOriginalUri = useCallback(
    (_oktaAuth: OktaAuth, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin), {
        replace: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const oktaLifecycleLog = useCallback(
    eventName => (tokenName: string) => {
      addBreadcrumb({
        message: `Okta event ${eventName} happen!`,
        data: { tokenName },
        level: 'log',
      });
    },
    []
  );

  useEffect(() => {
    oktaAuth.tokenManager.on('error', captureException);

    oktaAuth.tokenManager.on('renewed', oktaLifecycleLog('renewed'));
    oktaAuth.tokenManager.on('added', oktaLifecycleLog('added'));
    oktaAuth.tokenManager.on('expired', oktaLifecycleLog('expired'));
    oktaAuth.tokenManager.on('removed', oktaLifecycleLog('removed'));

    return () => {
      addBreadcrumb({
        message: 'Destroying the authentication context!',
        data: { application: 'offboarding-app' },
      });
    };
  }, [oktaLifecycleLog]);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};
