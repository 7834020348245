import React, { useCallback, useEffect } from 'react';

import { Control } from '@workmotion/dynamic-form-library';
import { useField, useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

import { Components } from 'networking/offboarding/offboarding-contract';

import { AdditionalInfoTip } from './additional-info-tip';
import { useAccessToken } from '../../authentication/use-access-token';
import { uploadOffboardingDocument } from '../../networking/offboarding/offboarding-client';

type DynamicSection = Components.Schemas.OffboardingSchemaSectionDTO;

/**
 * @deprecated
 * Should be removed after the fix for dynamic form value
 */
interface ControlBase {
  id: string;
  label: string;
  placeholder?: string;
  validations?: Array<string>;
  requestType: 'single_value' | 'multi_value' | 'no_value';
  componentType: ControlType;
  enabled: true | false;
  tip?: string;
  richTip?: string;
  newRichTip?: {
    message: string;
    url: string;
    urlTitle: string;
  };
  events?: ControlEvent[];
  required?: boolean;
  sectionId?: string;
  dataTestId?: string;
  constraints?: {
    min?: number;
    max?: number;
    regexes?: { expression: string; message?: string };
    specificDateId?: string;
    minDaysAfterSpecificDate?: number;
  }[];
  uniqueIdentifier?: string;
  disabled?: boolean;
  minDate?: string;
}

export type DocumentResponse =
  Components.Schemas.OffboardingDocumentResponseDTO;

/**
 * @deprecated
 * Should be removed after the fix for dynamic form value
 */
interface ControlEvent {
  onExactValue?: {
    values: Array<string | number | boolean>;
    eventHandler: {
      componentIdEnabler: Array<string>;
      readyOnlyComponents?: Array<never>;
    };
  };
}

/**
 * @deprecated
 * Should be removed after the fix for dynamic form value
 */
enum ControlType {
  INPUT = 'input',
  TEXT_AREA = 'text_area',
  TITLE = 'title',
  COLLECTION = 'collection',
  MULTI_CHECKBOX = 'multi_checkbox',
  OUTPUT = 'output',
  UPLOAD = 'file_uploader',
  SWITCH = 'toggle_switch',
}

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 1.5rem;

  background: ${palette.secondary.white};
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const useFileUpload = ({ offboardingId }: { offboardingId: string }) => {
  const { accessToken } = useAccessToken();

  const upload = useCallback(
    (files: File[]) =>
      Promise.all(
        files.map(file =>
          uploadOffboardingDocument(
            offboardingId,
            file,
            accessToken.accessToken
          ).then(response => response.data.id)
        )
      ),
    [accessToken, offboardingId]
  );

  return { upload };
};

export const Section: React.FC<{
  section: DynamicSection;
  changeEnabledComponents: (itemId: string, toEnable: boolean) => void;
  disabledComponent?: string | null;
  slug?: string;
  statusUpdateReason?: string;
}> = ({
  section,
  changeEnabledComponents,
  disabledComponent,
  slug,
  statusUpdateReason,
}) => {
  const { offboardingId } = useParams();
  const { upload } = useFileUpload({ offboardingId });

  const { setFieldValue } = useFormikContext();

  // We need to revisit this approach
  useEffect(() => {
    if (disabledComponent) setFieldValue(disabledComponent, undefined);
  }, [disabledComponent, setFieldValue]);

  return (
    <Card>
      {!!slug && slug === 'additional-information' && statusUpdateReason && (
        <AdditionalInfoTip statusUpdateReason={statusUpdateReason} />
      )}

      {section.components.map(component => (
        <Control
          useFormikContext={useFormikContext}
          useField={useField}
          {...(component as ControlBase)}
          key={component.id}
          fileUpload={upload}
          onEnablerValueChanged={changeEnabledComponents}
        />
      ))}
    </Card>
  );
};
