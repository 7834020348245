import React from 'react';

import { Button, Spinner, palette } from 'workmotion-design-system';
import { ButtonProps } from 'workmotion-design-system/components/Form/Button';

import { en } from 'localization';

export const LoadingButton: React.FC<
  {
    isLoading: boolean;
    btnText?: string;
    disabled?: boolean;
  } & ButtonProps
> = ({
  onClick,
  isLoading,
  disabled,
  btnText = en.offBoarding.guide.proceed,
  children,
  ...props
}) => (
  <Button
    onClick={onClick}
    {...props}
    disabled={disabled ? disabled : isLoading}
  >
    {!isLoading && btnText}

    {isLoading && (
      <Spinner
        size={20}
        ringColor={palette.greyscale.ghostWhite}
        spinnerColor={palette.primary.genericViridian}
      />
    )}
  </Button>
);
