import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

try {
  if (Sentry.getCurrentHub().getClient() !== undefined) {
    Sentry.addBreadcrumb({
      message: 'Sentry already initialized',
      level: 'info',
    });
  } else {
    Sentry.init({
      dsn: window.env.SENTRY_DSN,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes as unknown as never
          ),
        }),
      ],
      tracesSampleRate: Number(window.env.SENTRY_TRACES_RATE),
      environment: window.env.SENTRY_ENV,
      initialScope: {
        tags: { 'microfrontend-app': 'offboarding-app', team: 'Inferno' },
      },
    });
  }
} catch (e) {
  if (Sentry.getCurrentHub().getClient() !== undefined) {
    Sentry.captureException(e);
  } else {
    console.error(e);
  }
}
