import { Document, OpenAPIClientAxios } from 'openapi-client-axios';

import { Client, Components } from './offboarding-contract';
import { ManualInputs, TalentInputs } from './offboarding-requests';
import ClientDefinition from '../offboarding/offboarding-api-swagger.json';

export type ComponentRequest = Components.Schemas.ComponentRequest;

export type Offboarding = Components.Schemas.OffboardingResponseDTO;

export type Step = Components.Schemas.OffboardingSchemaStepDTO;

export type SchemaRequest = Components.Schemas.CreateOffboardingSchemaRequest;

export type OffboardingSchema = Components.Schemas.OffboardingSchemaDTO;

export type OffboardingFlow = Components.Schemas.OffboardingFlowResponse;

export type OffboardingFlowType = Components.Schemas.FlowType;

async function createOffboardingClient(accessToken: string) {
  if (accessToken == null) {
    console.error('Access token is null!');

    return;
  }

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  const api = new OpenAPIClientAxios({
    definition: ClientDefinition as Document,
    axiosConfigDefaults: {
      headers,
      baseURL: window.env.API_BASE_URL,
      /**
       * We need these parameters to solve internal library issues.
       * Check for version updates in order to fix it
       */
      transitional: {},
      paramsSerializer: {},
    },
  });

  await api.init();

  return api.getClient<Client>();
}

export async function getSchema(
  countryIsoCode: string,
  flowType: string,
  token: string
) {
  const client = await createOffboardingClient(token);

  const response = await client.getOffboardingSchema({
    countryIsoCode,
    flowType,
  });

  return response.data;
}

export async function getOffboardings(
  talentId: string,
  companyId: string,
  flowType: string,
  token: string
) {
  const client = await createOffboardingClient(token);

  const response = await client.getOffboardings({
    talentId,
    activeOnly: true,
    companyId,
    flowType,
  } as any);

  return response.data;
}

export async function startOffboarding(
  talentId: string,
  flowType: string,
  token: string
) {
  const client = await createOffboardingClient(token);

  const response = await client.startOffboarding({}, {
    talentId,
    flowType,
  } as any);

  return response.data;
}

export async function getOffboarding(offboardingId: string, token: string) {
  const client = await createOffboardingClient(token);

  const response = await client.getOffboarding({ offboardingId });

  const { data } = response;

  if (data.status !== 'AWAITING_INFORMATION') {
    return {
      ...data,
      offboardingSchema: {
        ...data.offboardingSchema,
        steps: data?.offboardingSchema.steps.filter(
          step => step.slug !== 'additional-information'
        ),
      },
    };
  }

  return data;
}

export async function saveStep(
  offboardingId: string,
  stepId: string,
  components: ComponentRequest[],
  token: string
) {
  const client = await createOffboardingClient(token);

  const response = await client.updateOffboardingStep(
    {
      offboardingId,
      stepId,
    },
    { componentRequests: components }
  );

  return response.data;
}

export async function uploadOffboardingDocument(
  offboardingId: string,
  file: File,
  token: string
) {
  const client = await createOffboardingClient(token);
  const formData = new FormData();

  formData.append('file', file);
  formData.append('fileContentType', file.type);
  formData.append('fileName', file.name);

  return client.uploadOffboardingDocument(
    { offboardingId },
    formData as never,
    {
      headers: {
        'Content-Type': undefined,
      },
    }
  );
}

export async function updateOffboardingStatus(
  offboardingId: string,
  token: string
) {
  const client = await createOffboardingClient(token);

  const response = await client.updateOffboardingStatus(
    {
      offboardingId,
    },
    { status: 'PENDING' }
  );

  return response.data;
}

export async function fetchRawSchema(
  countryIsoCode: string,
  flowType: string,
  token: string
) {
  const client = await createOffboardingClient(token);

  const response = await client.getRawOffboardingSchema({
    countryIsoCode,
    flowType,
  });

  return response.data;
}

export async function compileRawSchema(schema: SchemaRequest, token: string) {
  const client = await createOffboardingClient(token);

  const response = await client.createOffboardingSchema(
    { dryRun: true },
    schema
  );

  return response.data;
}

export async function getOffboardingFlows(talentId: string, token: string) {
  const client = await createOffboardingClient(token);

  const response = await client.getOffboardingFlows({
    talentId,
  });

  return response.data.flows.reverse();
}

export async function calculateOffboardingCostManually(
  inputs: ManualInputs,
  token: string
) {
  const client = await createOffboardingClient(token);

  const response = await client.calculateOffboardingCost(null, inputs);

  return response.data;
}

export async function calculateOffboardingCostForTalent(
  inputs: TalentInputs,
  token: string
) {
  const client = await createOffboardingClient(token);

  const response = await client.calculateEmployeeOffboardingCost({
    employeeId: inputs.talentId,
  });

  return response.data;
}

export async function getCalculationAvailableCountryCodes(token: string) {
  const client = await createOffboardingClient(token);

  const response = await client.getOffboardingCostCalculationConfig();

  return response.data.supportedCountryCodes;
}
