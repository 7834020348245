import React, { useCallback, useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import { Drawer } from 'workmotion-design-system';

import { en } from 'localization';
import { OffboardingFlow } from 'networking/offboarding/offboarding-client';

import { DrawerContent } from './drawer-content';
import { Card, Description, Link, Title } from './termination-options.styled';

export interface OptionCardProps {
  title?: string;
  flow: OffboardingFlow;
  country: string;
}

const {
  terminationOptions: { learnMore, fixedTermCardTitle, normalTerminationTitle },
} = en;

export const OptionCard: React.FC<OptionCardProps> = ({
  title,
  flow,
  country,
}) => {
  const [cardClicked, setCardClicked] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [searchParams, setSearchParam] = useSearchParams();
  const selectedFlow = searchParams.get('flow');

  const cardClickHandler = useCallback(() => {
    setCardClicked(true);
    setSearchParam({ flow: `${flow?.flowType}` });
  }, [flow?.flowType, setSearchParam]);

  useEffect(() => {
    if (flow.flowType === selectedFlow) {
      setCardClicked(true);
    } else setCardClicked(false);
  }, [flow.flowType, selectedFlow]);

  return (
    <Card
      clicked={cardClicked}
      onClick={cardClickHandler}
      data-testid={`termination-card-option-${flow.flowType}`}
      disabled={true}
    >
      <Title data-testid="termination-card-title">{title}</Title>

      <Description data-testid="termination-card-description">
        {flow.terminationInformation.contentMarkdown}
      </Description>

      <Link
        data-testid="termination-card-link"
        onClick={() => setIsDrawerOpen(prev => !prev)}
      >
        {learnMore}
      </Link>

      <Drawer
        title={title}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        anchor={'right'}
        data-testid="termination-card-Drawer"
      >
        <DrawerContent
          country={country}
          markDown={flow.terminationInformation.contentMarkdown}
          flow={
            flow?.flowType === 'TERMINATION'
              ? normalTerminationTitle
              : fixedTermCardTitle
          }
        />
      </Drawer>
    </Card>
  );
};
