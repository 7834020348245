import React, { Suspense } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AppRoutes } from './app';
import { AuthenticationContext } from './authentication/authentication-context';
import { theme } from './mui-theme';
import { NetworkContext } from './networking/network-context';
import { ErrorBoundary } from './tracking/error-boundary';

const WrappedRootComponent: React.FC = () => (
  <Suspense fallback={<span>Spinner</span>}>
    <NetworkContext>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthenticationContext>
            <ErrorBoundary>
              <AppRoutes />
            </ErrorBoundary>
          </AuthenticationContext>
        </BrowserRouter>
      </ThemeProvider>
    </NetworkContext>

    <ToastContainer />
  </Suspense>
);

export default Sentry.withProfiler(WrappedRootComponent);
