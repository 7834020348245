import React from 'react';

import styled from 'styled-components';
import { Spinner } from 'workmotion-design-system';

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 98vh;
`;

export const PageLoader: React.FC = () => (
  <CenteredContainer>
    <Spinner></Spinner>
  </CenteredContainer>
);
