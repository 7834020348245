import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { useFlags } from '@workmotion/feature-flags-library';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  Button,
  ErrorFallback,
  LayoutWrapper,
  Markdown,
  palette,
} from 'workmotion-design-system';

import { CaptionComponent } from './caption-component';
import { LoadingButton } from './loading-button';
import { TerminationOptions } from './termination-options';
import { en } from '../../localization';
import { useTalentProfile } from '../../networking/employee-requests';
import { useStartOffboardingMutation } from '../../networking/offboarding/offboarding-mutations';
import {
  useGetOffboardingFlows,
  useOffboardingSchema,
  useTalentOffboardings,
} from '../../networking/offboarding/offboarding-requests';
import { PageLoader } from '../../networking/page-loader';
import { Content, Footer, Description, Title } from '../design-components';
import { Card } from '../steps/section';

export const Guide: React.FC = () => {
  const navigate = useNavigate();

  const { applicationId } = useParams();
  const [searchParams, setSearchParam] = useSearchParams();
  const selectedFlow = searchParams.get('flow');

  const [showTerminationFlowsCards, setShowTerminationFlowsCards] =
    useState<boolean>(false);

  const {
    isLoading: isTalentProfileLoading,
    data: talentProfile,
    isError: isProfileError,
  } = useTalentProfile({ applicationId });

  const { isLoading: isStartingOffboading, mutate } =
    useStartOffboardingMutation(talentProfile?.id, selectedFlow);

  const { inferno1928SupportContractExpiryTerminationFlow } = useFlags();

  const { isInitialLoading: isInfoLoading, data: schema } =
    useOffboardingSchema(talentProfile?.countryISOCode, selectedFlow);

  const { data: offboardings, isInitialLoading: talentOffboardingsLoading } =
    useTalentOffboardings(talentProfile?.id, selectedFlow);

  const {
    isInitialLoading: flowsLoading,
    data: availableTerminationFlows,
    isError: flowsError,
  } = useGetOffboardingFlows(talentProfile?.id);

  const startOffboarding = useCallback(() => {
    if (offboardings?.content.length === 0) {
      mutate(null, {
        onSuccess: response => {
          navigate(`/offboarding/${response.id}/${schema.steps[0].slug}`);
        },
      });
    } else {
      const activeOffboarding = offboardings.content[0];

      navigate(`/offboarding/${activeOffboarding.id}/${schema.steps[0].slug}`);
    }
  }, [offboardings?.content, mutate, navigate, schema?.steps]);

  const onCloseClick = useCallback(() => {
    navigate('/talents/offboardings');
  }, [navigate]);

  useEffect(() => {
    if (
      inferno1928SupportContractExpiryTerminationFlow &&
      !flowsLoading &&
      availableTerminationFlows &&
      availableTerminationFlows?.length > 1
    ) {
      setShowTerminationFlowsCards(true);
    } else if (availableTerminationFlows?.length === 1) {
      setSearchParam({ flow: 'TERMINATION' });
      setShowTerminationFlowsCards(false);
    }
  }, [
    inferno1928SupportContractExpiryTerminationFlow,
    availableTerminationFlows,
    selectedFlow,
    flowsLoading,
    flowsError,
    setSearchParam,
    searchParams,
    navigate,
  ]);

  useEffect(() => {
    if (offboardings?.content?.length > 0) navigate('/talents/offboardings');
  }, [navigate, offboardings?.content?.length]);

  if (
    isTalentProfileLoading ||
    flowsLoading ||
    (availableTerminationFlows?.length === 1 && isInfoLoading)
  ) {
    return <PageLoader />;
  }

  if (
    (!isTalentProfileLoading && isProfileError) ||
    (!flowsLoading && flowsError)
  ) {
    return <ErrorFallback />;
  }

  return (
    <Box
      sx={{
        backgroundColor: '#eff0f5',
        height: '100vh',
        display: 'flex',
      }}
    >
      <LayoutWrapper
        onLogoClick={() => navigate('/')}
        layoutCaption={
          <CaptionComponent
            countryCode={talentProfile?.countryISOCode}
            countryName={talentProfile?.countryName}
            talentId={talentProfile?.id}
            talentName={`${talentProfile.firstName} ${talentProfile.lastName}`}
            terminationLink={
              schema?.terminationInformation.terminationGuideLink
            }
            infoLoading={isInfoLoading}
          />
        }
      >
        {!flowsError && !flowsLoading && (
          <>
            {showTerminationFlowsCards ? (
              <Content direction="center" style={{ marginTop: 11 }}>
                <TerminationOptions
                  flows={availableTerminationFlows}
                  country={talentProfile?.countryName}
                />
              </Content>
            ) : (
              <Content direction="start">
                <Title>{en.offBoarding.guide.title}</Title>

                <Description data-cy="description">
                  {en.offBoarding.guide.subtitle}
                </Description>

                <Card
                  style={{
                    padding: '0 2rem',

                    maxHeight: '80%',
                    overflow: 'scroll',
                  }}
                  data-cy="country-information"
                  data-testid="markdown"
                >
                  <Markdown
                    styles={{ color: palette.primary.midnightGreen }}
                    text={schema?.terminationInformation.contentMarkdown}
                  />
                </Card>
              </Content>
            )}

            <Footer>
              <>
                <Button
                  btnType={'secondary'}
                  onClick={onCloseClick}
                  data-cy="offboarding-guide-close"
                >
                  {en.offBoarding.guide.close}
                </Button>

                {showTerminationFlowsCards ? (
                  <LoadingButton
                    onClick={startOffboarding}
                    data-cy="begin-temination-btn"
                    style={{ width: 190 }}
                    disabled={selectedFlow === null}
                    isLoading={
                      isInfoLoading ||
                      talentOffboardingsLoading ||
                      isStartingOffboading
                    }
                    btnText={en.terminationOptions.beginOffboardingBtn}
                  ></LoadingButton>
                ) : (
                  <LoadingButton
                    onClick={startOffboarding}
                    isLoading={isStartingOffboading}
                    data-cy="offboarding-guide-proceed"
                  >
                    {en.offBoarding.guide.proceed}
                  </LoadingButton>
                )}
              </>
            </Footer>
          </>
        )}
      </LayoutWrapper>
    </Box>
  );
};
