const enSomethingWentWrong =
  'Sorry for the inconvenience, we are experiencing some issues and working to get them fixed ASAP.';

export const en = {
  generic: {
    pageNotFoundScreen: {
      title: 'Page not found',
      subTitle1: 'The page you were looking for could not be found.',
      subTitle2:
        'It might have been removed, renamed, or did not exist in the first place.',
    },
    genricError: enSomethingWentWrong,
  },
  sharedComponents: {
    errorFallback: {
      somethingWentWrong: 'Hmm.. Something went wrong.',
      weHaveBeenNotified:
        'We have been notified with the problem and we will be investigating this as fast as we can',
      retry: 'Retry',
    },
  },
  offBoarding: {
    guide: {
      proceed: 'Proceed',
      close: 'Close',
      title: 'Before getting started',
      subtitle: 'Information about terminating a talent',
      error: enSomethingWentWrong,
      proceedFixedTerm: 'Submit',
      caption: {
        title: 'Offboarding Guide',
        link: 'Learn more about the offboarding process',
      },
    },
    steps: {
      caption: {
        title: talentName => `Offboarding "${talentName}"`,
      },
      additionalInformation: {
        tipTitle: 'Please provide the following additional information:',
      },
      back: 'Back',
      close: 'Close',
      continue: 'Continue',

      stepsProgress: {
        saveAsDraft: 'Save as draft',
        logoutLabel: 'Logout',
        stepsProgress: (
          currentStepNumber: number,
          totalStepsNumber: number
        ): string => `Step ${currentStepNumber} of ${totalStepsNumber}`,
      },
    },
    deleteConfirmationPopup: {
      title: 'Off-boarding Confirmation',
      descriptionMessage: talentName =>
        `Are you sure you want to offboard ${talentName} contract?`,
      primaryButton: 'Offboard',
      secondryButton: 'cancel',
    },
    confirmationPopup: {
      primaryBtn: 'Done',
      title: 'Offboarding request sent',
      descriptionContent:
        'Thank you, we have received the Offboarding request\n.Our team will assess your case and get back to you in\n 48 hours.',
    },
  },
  terminationOptions: {
    terminationHeader: 'Select offboarding type',
    terminationDescription: 'Information about offboarding your talent',
    beginOffboardingBtn: 'begin offboarding',
    fixedTermCardTitle: 'Fixed-term Contract Expiry',
    normalTerminationTitle: 'Termination',
    DrawerTitle: (terminationType, country) =>
      `${terminationType} in ${country}`,
    learnMore: 'Learn More',
  },
};
