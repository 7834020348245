import { createTheme } from '@mui/material';
import { palette } from 'workmotion-design-system';

export const theme = createTheme({
  typography: {
    fontFamily: ['Work Sans', 'sans-serif'].join(','),
    fontSize: 14,
    allVariants: {
      lineHeight: '1.375rem',
      color: palette.greyscale.UCLABlue,
    },
    h2: {
      fontSize: '16px',
      fontWeight: 600,
      color: palette.primary.genericViridian,
    },
    h3: {
      fontSize: '14px',
      fontWeight: 600,
    },
    body1: {
      fontWeight: 400,
      fontSize: '14px',
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
          maxHeight: '70vh',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: palette.primary.genericViridian,
          fontSize: 14,
          padding: '32px 32px 24px 32px',
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          color: palette.primary.genericViridian,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: palette.tertiary.aquaHaze,
          },
          '&.Mui-focusVisible': {
            backgroundColor: 'none',
          },
          '&.Mui-selected': {
            backgroundColor: palette.greyscale.antiFlashWhite,
          },
        },
      },
    },
  },
});
