import React from 'react';

import { Box } from '@mui/material';
import { useFlags } from '@workmotion/feature-flags-library';
import { ReactCountryFlag } from 'react-country-flag';
import { Spinner } from 'workmotion-design-system';

import { en } from 'localization';

import { useCalculationAvailableCountryCodes } from '../../../networking/offboarding/offboarding-requests';
import { CaptionColumn, Country, GuideLink } from '../../design-components';
import { OffboardingCost } from '../offboarding-cost';

export type CaptionProps = {
  countryCode: string;
  countryName: string;
  terminationLink: string;
  infoLoading: boolean;
  talentId: string;
  talentName: string;
};

export const CaptionComponent = ({
  countryCode,
  countryName,
  terminationLink,
  infoLoading,
  talentId,
  talentName,
}: CaptionProps) => {
  const { inferno2499InputDetailsManuallyScreenForTheOcc } = useFlags();
  const { isLoading, data } = useCalculationAvailableCountryCodes();

  return (
    <CaptionColumn>
      <p>{en.offBoarding.guide.caption.title}</p>

      <Box>
        <ReactCountryFlag
          countryCode={countryCode}
          svg
          cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
          cdnSuffix="svg"
          style={{
            width: '32px',
            height: '32px',
            borderRadius: '21px',
          }}
        />

        <Country>in {countryName}</Country>
      </Box>

      {infoLoading && <Spinner data-testid="caption-component-spinner" />}

      {terminationLink && !infoLoading && (
        <GuideLink terminationGuideLink={terminationLink} />
      )}

      {inferno2499InputDetailsManuallyScreenForTheOcc &&
        !isLoading &&
        data.includes(countryCode) && (
          <OffboardingCost talentId={talentId} talentName={talentName} />
        )}
    </CaptionColumn>
  );
};
