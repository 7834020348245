import React from 'react';

import LightbulbIcon from '@mui/icons-material/LightbulbOutlined';
import { Markdown, palette } from 'workmotion-design-system';

import { en } from 'localization';

import {
  DrawerContentContainer,
  DrawerSubTitle,
  Icon,
} from './termination-options.styled';

const {
  terminationOptions: { DrawerTitle },
} = en;

export const DrawerContent: React.FC<{
  country: string;
  markDown: string;
  flow: string;
}> = ({ country, markDown, flow }) => (
  <DrawerContentContainer data-testid="Drawer-content">
    <Icon>
      <LightbulbIcon
        style={{
          color: `${palette.semantic.toyBlue}`,
        }}
      />
    </Icon>

    <DrawerSubTitle>{DrawerTitle(flow, country)}</DrawerSubTitle>

    <Markdown
      styles={{
        color: palette.primary.midnightGreen,
      }}
      text={markDown}
    />
  </DrawerContentContainer>
);
