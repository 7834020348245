import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { AxiosError } from 'axios';
import { ReactCountryFlag } from 'react-country-flag';
import { Route, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as DesignSystem from 'workmotion-design-system';
import {
  ErrorFallback,
  Markdown,
  PageNotFound,
  palette,
} from 'workmotion-design-system';

import { Step } from './step';
import { StepsProgress } from './steps-progress';
import { en } from '../../localization';
import { useTalentProfile } from '../../networking/employee-requests';
import { useOffboarding } from '../../networking/offboarding/offboarding-requests';
import { PageLoader } from '../../networking/page-loader';
import { SentryRoutes } from '../../tracking/routes';
import { CaptionColumn, Country, GuideLink } from '../design-components';

const CaptionComponent = ({
  countryCode,
  countryName,
  terminationLink,
  contentMarkdown,
}) => (
  <CaptionColumn>
    <Box>
      <ReactCountryFlag
        countryCode={countryCode}
        svg
        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
        cdnSuffix="svg"
        style={{
          width: '32px',
          height: '32px',
          borderRadius: '21px',
        }}
      />

      <Country>in {countryName}</Country>
    </Box>

    <Markdown
      styles={{ color: palette.primary.midnightGreen }}
      text={contentMarkdown}
    />

    {terminationLink == null ? (
      <DesignSystem.Spinner />
    ) : (
      <GuideLink terminationGuideLink={terminationLink} />
    )}
  </CaptionColumn>
);

export const Steps: React.FC = () => {
  const { offboardingId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    isLoading,
    data: offboarding,
    isError,
    error,
  } = useOffboarding(offboardingId);

  const talentQ = useTalentProfile({ talentId: offboarding?.talentId });

  const [activeStepIndex, setActiveStepIndex] = useState(-1);

  useEffect(() => {
    if (error && (error as AxiosError).response.status === 403) {
      navigate('/');
    }
  }, [error, navigate]);

  useEffect(() => {
    if (offboarding == null) {
      setActiveStepIndex(-1);
    } else {
      const currentIndex = offboarding.offboardingSchema.steps.findIndex(step =>
        pathname.includes(step.slug)
      );

      if (currentIndex != -1) {
        setActiveStepIndex(currentIndex + 1);
      } else {
        setActiveStepIndex(-1);
      }
    }
  }, [offboarding, pathname]);

  if (isLoading) {
    return (
      <SentryRoutes>
        <Route path={'*'} element={<PageLoader />} />
      </SentryRoutes>
    );
  }

  if (isError) {
    return (
      <div data-testid="Error-fallback">
        <ErrorFallback />
      </div>
    );
  }

  return (
    <Box
      className={'offboarding-steps'}
      sx={{
        backgroundColor: '#eff0f5',
        height: '100%',
        display: 'flex',
        overflowY: 'scroll',
        maxHeight: '100vh',
      }}
      id="offboarding-main-view"
    >
      <DesignSystem.LayoutWrapper
        onLogoClick={() => navigate('/')}
        layoutCaption={en.offBoarding.steps.caption.title(
          talentQ.data?.firstName || ''
        )}
        content={
          talentQ.isLoading ? (
            <DesignSystem.Spinner data-testid="Spinner" />
          ) : (
            <CaptionComponent
              countryCode={talentQ.data.countryISOCode}
              countryName={talentQ.data.countryName}
              contentMarkdown={
                offboarding.offboardingSchema.terminationInformation
                  .contentMarkdown
              }
              terminationLink={
                offboarding.offboardingSchema.terminationInformation
                  .terminationGuideLink
              }
            />
          )
        }
      >
        {isLoading && !isError && (
          <SentryRoutes>
            <Route path={'*'} element={<PageLoader />} />{' '}
          </SentryRoutes>
        )}

        {!isLoading && !isError && (
          <>
            {activeStepIndex != -1 &&
              offboarding.offboardingSchema.steps.length > 1 && (
                <StepsProgress
                  currentStepNumber={activeStepIndex}
                  totalStepsCount={offboarding.offboardingSchema.steps.length}
                />
              )}

            <SentryRoutes>
              <Route path=":slug" element={<Step />} />

              <Route path={'*'} element={<PageNotFound />} />
            </SentryRoutes>
          </>
        )}
      </DesignSystem.LayoutWrapper>
    </Box>
  );
};
