import React from 'react';

import { Box } from '@mui/material';
import { InfoTip } from 'workmotion-design-system';

import { en } from '../../localization';

const {
  offBoarding: {
    steps: {
      additionalInformation: { tipTitle },
    },
  },
} = en;

export const AdditionalInfoTip: React.FC<{ statusUpdateReason: string }> = ({
  statusUpdateReason,
}) => (
  <Box sx={{ marginBottom: '2rem' }} data-testid="AdditionalInfoTip">
    <InfoTip
      title={tipTitle}
      tipType={'info'}
      text={statusUpdateReason}
      mdStyle={{ marginTop: '.5rem' }}
    />
  </Box>
);
