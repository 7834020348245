import { Document, OpenAPIClientAxios } from 'openapi-client-axios';

import { Client } from './employee-contract';
import EmployeeDefinition from './employeeAPI-v2.0.68-swagger.json';

async function createEmployeeClient(accessToken: string) {
  if (accessToken == null) {
    console.error('Access token is null!');

    return;
  }

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  const api = new OpenAPIClientAxios({
    definition: EmployeeDefinition as Document,
    axiosConfigDefaults: {
      headers,
      baseURL: window.env.API_BASE_URL,
      /**
       * We need these parameters to solve internal library issues.
       * Check for version updates in order to fix it
       */
      transitional: {},
      paramsSerializer: {},
    },
  });

  await api.init();

  return api.getClient<Client>();
}

export async function getEmployeeProfileByApplicationId(
  applicationId: string,
  token: string
) {
  const client = await createEmployeeClient(token);

  const response = await client.getEmployeeByApplicationIdOrEmployeeNumber({
    applicationId,
  } as never);

  return response.data;
}

export async function getEmployeeProfile(talentId: string, token: string) {
  const client = await createEmployeeClient(token);

  const response = await client.getEmployee({
    employeeId: talentId,
  } as never);

  return response.data;
}

export async function getAllEmployees(
  params: {
    companyId?: string;
    searchTerm?: string;
    size: number;
    orderBy?: string;
    direction?: string;
  },
  token: string
) {
  const client = await createEmployeeClient(token);

  const response = await client.getAllEmployees(params);

  return response.data;
}
