import React from 'react';

import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalButtonsFooter, palette } from 'workmotion-design-system';

import { ModalPopupProps } from 'app/offboarding-popup';
import { en } from 'localization';

const ConfirmationModal: React.FC<ModalPopupProps> = ({
  isOpen,
  closeModal,
}) => {
  const {
    offBoarding: {
      confirmationPopup: { title, descriptionContent, primaryBtn },
    },
  } = en;

  const navigate = useNavigate();

  return (
    <Modal
      closeModal={closeModal}
      addCloseIcon={false}
      defaultContent={{
        icon: (
          <PersonRemoveIcon
            sx={{
              color: palette.primary.genericViridian,
              fontSize: '1.875rem',
            }}
          />
        ),
        text: (
          <p
            data-testid="description-content"
            style={{ whiteSpace: 'pre-line' }}
          >
            {descriptionContent}
          </p>
        ),
        title: title,
      }}
      footer={
        <ModalButtonsFooter
          placement="center"
          buttonsInfo={[
            {
              btnType: 'default',
              text: primaryBtn,
              type: 'button',
              onClick: () => navigate('/talents/offboardings'),
              disabled: false,
              dataCy: 'done-btn',
            },
          ]}
        />
      }
      type="info"
      isOpen={isOpen}
    />
  );
};

export default ConfirmationModal;
