import React from 'react';

// eslint-disable-next-line import/named
import { Box, SxProps } from '@mui/material';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

import { en } from '../localization';

export const Country = styled.p({
  fontSize: '1rem',
  color: `${palette.greyscale.persianBlue}`,
  marginLeft: '0.75rem',
  display: 'inline',
  fontFamily: 'Work Sans',
  fontWeight: 500,
});

export const Link = styled.a({
  fontWeight: '400',
  lineHeight: '1.25rem',
  fontSize: '0.875rem',
  color: `${palette.primary.cerisePink}`,
  cursor: 'pointer',
  textDecoration: 'none',
  marginBottom: '2rem',
});

export const GuideLink = ({ terminationGuideLink }) => (
  <Link target="_blank" href={terminationGuideLink}>
    {en.offBoarding.guide.caption.link}
  </Link>
);

export const CaptionColumn = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'start',
      gap: '0.5rem',
    }}
    data-testid="caption-column"
  >
    {children}
  </Box>
);

export const Content: React.FC<{
  direction: 'center' | 'start';
  style?: SxProps;
  children?: React.ReactNode;
}> = ({ direction, style = {}, children }) => (
  <Box
    sx={{
      padding: '0 2rem',
      alignItems: direction,
      minHeight: '70vh',
      flex: 1,
      ...style,
    }}
  >
    {children}
  </Box>
);

export const Footer: React.FC<{
  style?: SxProps;
  children?: React.ReactNode;
}> = ({ children, style = {} }) => (
  <Box
    component={'footer'}
    sx={{
      display: 'flex',
      flexDirection: 'row',
      margin: '2rem',
      justifyContent: 'space-between',
      alignSelf: 'stretch',
      ...style,
    }}
  >
    {children}
  </Box>
);

export const Title = styled.p`
  font-style: normal;
  margin-top: 0.75rem;
  font-weight: 500;
  font-size: 1.5rem;
  color: ${palette.primary.genericViridian};
`;

export const Description = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 0.785rem;
  line-height: 22px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${palette.greyscale.UCLABlue};
`;
