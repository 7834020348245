import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const Card = styled.div<{ clicked: boolean }>(({ clicked }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '1.5rem',
  width: '25.6875rem',
  background: `${palette.secondary.white}`,
  border: clicked
    ? `1px solid ${palette.primary.genericViridian}`
    : `1px solid ${palette.secondary.white}`,
  borderRadius: '0.75rem',
}));

export const Title = styled.p({
  fontFamily: 'Work Sans',
  fontWeight: 600,
  fontSize: '1rem',
  marginBottom: '0.5rem',
  lineHeight: '1.25rem',

  color: `${palette.greyscale.persianBlue}`,
});

export const Description = styled.p({
  width: '22.6875rem',
  maxHeight: '5em',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontFamily: 'Work Sans',
  fontWeight: '300',
  fontSize: '0.75rem',
  lineHeight: '1.25rem',
  color: `${palette.greyscale.UCLABlue}`,
});

export const Link = styled.p({
  fontWeight: '300',
  fontSize: '0.75rem',
  lineHeight: '1.25rem',
  color: `${palette.primary.cerisePink}`,
  cursor: 'pointer',
});

export const TerminationContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const CardsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '1.25rem',
});

export const TerminationHeader = styled.p({
  color: `${palette.primary.genericViridian}`,
  fontSize: '1.5rem',
  marginBottom: '0.75rem',

  fontWeight: '500',
});

export const TerminationDescription = styled.p({
  color: `${palette.greyscale.UCLABlue}`,
  fontSize: '0.875rem',
  marginBottom: '1.625rem',
  fontWeight: '300',
});

// Drawer
export const DrawerContentContainer = styled.div({
  marginTop: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0.5rem',
  width: '25.75rem',
  marginLeft: '2rem',
});

export const Icon = styled.div({
  width: '2.5rem',
  height: '2.5rem',
  borderRadius: '50%',
  backgroundColor: `${palette.semantic.babyBlue}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const DrawerSubTitle = styled.div({
  fontWeight: 600,
  fontSize: '0.875rem',
  lineHeight: '1.375rem',
  color: `${palette.semantic.toyBlue}`,
});
