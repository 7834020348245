import React, { useCallback, useEffect, useState } from 'react';

import { addBreadcrumb } from '@sentry/react';
import {
  getInitialValues,
  getValidationSchema,
  ComponentRequestType,
} from '@workmotion/dynamic-form-library';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'workmotion-design-system';
import * as yup from 'yup';

import { LoadingButton } from 'app/guide/loading-button';
import OffboardingPopup from 'app/offboarding-popup';

import { Section } from './section';
import { en } from '../../localization';
import { useTalentProfile } from '../../networking/employee-requests';
import {
  ComponentRequest,
  Offboarding,
  OffboardingSchema,
  Step as StepDTO,
} from '../../networking/offboarding/offboarding-client';
import { useSaveOffboardingStepMutation } from '../../networking/offboarding/offboarding-mutations';
import { useOffboarding } from '../../networking/offboarding/offboarding-requests';
import { Content, Footer, Description, Title } from '../design-components';

interface Components extends ComponentRequest {
  value?: {
    id: string;
  }[];
}

export function extractComponents(step: StepDTO) {
  return step.sections
    .map(s => s.components)
    .reduce((pV, cV) => [...pV, ...cV]);
}

export function getValidationSchemaForStep(step: StepDTO) {
  const components = extractComponents(step);

  return yup.object().shape(getValidationSchema(components));
}

export function useOffboardingStepForm({
  offboardingSchema,
  slug,
}: {
  offboardingSchema: OffboardingSchema;
  slug: string;
}) {
  const [currentStep, setCurrentStep] = useState(
    offboardingSchema.steps.find(step => step.slug === slug) || null
  );

  const [index, setIndex] = useState(
    offboardingSchema.steps.findIndex(step => step.slug === slug)
  );

  const [initialValues, setInitialValues] = useState(
    getInitialValues(extractComponents(currentStep))
  );

  const [schema, setSchema] = useState(getValidationSchemaForStep(currentStep));

  const [disabledComponent, setDisabledComponent] = useState<string | null>();

  const changeEnabledComponents = useCallback(
    (id: string, toEnable: boolean) => {
      if (id) {
        const newSections = currentStep?.sections?.map(section => ({
          components: section.components.map(component => {
            if (component.id === id) {
              if (!toEnable) {
                setDisabledComponent(id);
              } else {
                setDisabledComponent(null);
              }

              return { ...component, enabled: toEnable };
            } else return component;
          }),
        }));

        const newCurrentStep = { ...currentStep, sections: newSections };

        setCurrentStep(newCurrentStep);
        setSchema(getValidationSchemaForStep(newCurrentStep));
      }
    },
    [currentStep]
  );

  const initializeStep = useCallback(
    (s: string) => {
      const stepDTO = offboardingSchema.steps.find(step => step.slug === s);

      setCurrentStep(stepDTO);

      const stepIndex = offboardingSchema.steps.findIndex(
        step => step.slug === s
      );

      setInitialValues(getInitialValues(extractComponents(stepDTO)));
      setSchema(getValidationSchemaForStep(stepDTO));

      setIndex(stepIndex);
    },
    [offboardingSchema.steps]
  );

  useEffect(() => {
    initializeStep(slug);
  }, [initializeStep, slug]);

  return {
    currentStep,
    index,
    initialValues,
    schema,
    changeEnabledComponents,
    disabledComponent,
  };
}

function useNextStepUrl(offboarding: Offboarding, currentStep: StepDTO) {
  const steps = offboarding.offboardingSchema.steps;

  const [nextStepUrl, setNextStepUrl] = useState<string>();
  const [isLastStep, setIsLastStep] = useState<boolean>(false);

  const updateURL = useCallback(
    stepId => {
      const index = offboarding.offboardingSchema.steps.findIndex(
        step => step.id === stepId
      );

      const updatedIsLastStep = () => index === steps.length - 1;

      setIsLastStep(updatedIsLastStep());

      setNextStepUrl(
        updatedIsLastStep()
          ? '/'
          : `/offboarding/${offboarding.id}/${steps[index + 1].slug}`
      );
    },
    [offboarding.id, offboarding.offboardingSchema.steps, steps]
  );

  useEffect(() => {
    updateURL(currentStep.id);
  }, [currentStep.id, updateURL]);

  return { nextStepUrl, isLastStep };
}

export const Step: React.FC = () => {
  const navigate = useNavigate();
  const { offboardingId, slug } = useParams();

  const { data: offboarding } = useOffboarding(offboardingId);
  const { data: talent } = useTalentProfile({ talentId: offboarding.talentId });

  const {
    currentStep,
    schema,
    initialValues,
    changeEnabledComponents,
    disabledComponent,
  } = useOffboardingStepForm({
    offboardingSchema: offboarding?.offboardingSchema,
    slug,
  });

  const { nextStepUrl, isLastStep } = useNextStepUrl(offboarding, currentStep);

  const saveOffboardingMutation = useSaveOffboardingStepMutation(offboardingId);

  const [modalIsOpen, setModelIsOpen] = useState<boolean>(false);

  const saveStepAndRedirect = useCallback(
    async (values: { [key: string]: ComponentRequest }) => {
      let components = Object.values(values);

      components = components.map((component: Components) => {
        if (
          component.value &&
          Array.isArray(component.value) &&
          component.value.every(v => Object.keys(v).includes('fileName'))
        ) {
          return {
            id: component.id,
            componentRequestType: ComponentRequestType.FileRequest,
            filesIds: component.value?.map(item => item.id),
          };
        } else return component;
      });

      await saveOffboardingMutation.mutateAsync({
        stepId: currentStep.id,
        components,
      });

      addBreadcrumb({
        message: `Finished saving step ${slug} succeeded!`,
      });

      if (isLastStep && offboarding.status === 'DRAFT') setModelIsOpen(true);
      else navigate(nextStepUrl);
    },
    [
      isLastStep,
      currentStep.id,
      saveOffboardingMutation,
      navigate,
      nextStepUrl,
      slug,
      offboarding.status,
    ]
  );

  useEffect(() => {
    const mainView = document.getElementById('offboarding-main-view');

    if (mainView) {
      mainView.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentStep.slug]);

  return (
    <div data-testid="Section">
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={saveStepAndRedirect}
        key={slug}
        enableReinitialize
      >
        {({ isSubmitting, status }) => (
          <Form>
            <Content
              direction="center"
              style={{
                maxWidth: '460px',
                alignSelf: 'center',
              }}
            >
              {currentStep.title && <Title>{currentStep.title}</Title>}

              {currentStep.description && (
                <Description data-testid="description-content">
                  {currentStep.description}

                  {` ${talent?.firstName} ${talent?.lastName}`}
                </Description>
              )}

              {currentStep.sections.map(section => (
                <Section
                  section={section}
                  key={section.title || section.components[0].id}
                  changeEnabledComponents={changeEnabledComponents}
                  disabledComponent={disabledComponent}
                  slug={currentStep.slug}
                  statusUpdateReason={offboarding.statusUpdateReason}
                />
              ))}
            </Content>

            <Footer>
              <Button
                btnType={'secondary'}
                onClick={() => navigate(-1)}
                data-cy="cancel-btn"
              >
                {offboarding.offboardingSchema.flowType === 'CONTRACT_EXPIRY'
                  ? en.offBoarding.steps.close
                  : en.offBoarding.steps.back}
              </Button>

              <LoadingButton
                isLoading={isSubmitting || status === 'WITH_UNLOADED_FILES'}
                type={'submit'}
                data-cy="proceed-btn"
                btnText={
                  offboarding.offboardingSchema.flowType === 'CONTRACT_EXPIRY'
                    ? en.offBoarding.guide.proceedFixedTerm
                    : en.offBoarding.guide.proceed
                }
              ></LoadingButton>
            </Footer>

            <OffboardingPopup
              isOpen={modalIsOpen}
              closeModal={() => {
                setModelIsOpen(false);
              }}
              talentName={`${talent?.firstName} ${talent?.lastName}`}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
