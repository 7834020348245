import { useCallback } from 'react';

import {
  // eslint-disable-next-line import/named
  QueryObserverLoadingErrorResult,
  // eslint-disable-next-line import/named
  QueryObserverLoadingResult,
  // eslint-disable-next-line import/named
  QueryObserverRefetchErrorResult,
  // eslint-disable-next-line import/named
  QueryObserverSuccessResult,
  useQuery,
} from '@tanstack/react-query';

import {
  getAllEmployees,
  getEmployeeProfile,
  getEmployeeProfileByApplicationId,
} from './employee-client';
import { Components } from './employee-contract';
import { useAccessToken } from '../authentication/use-access-token';
import {
  AuthenticatedIdentity,
  useUserIdentity,
} from '../authentication/use-user-identity';

function isByApplicationId(param: {
  [key: string]: unknown;
}): param is { applicationId: string } {
  return param !== null && param.applicationId !== undefined;
}

type ResultQueryType =
  | QueryObserverRefetchErrorResult<
      Components.Schemas.EmployeeResponseDTO,
      unknown
    >
  | QueryObserverSuccessResult<Components.Schemas.EmployeeResponseDTO, unknown>
  | QueryObserverLoadingErrorResult<
      Components.Schemas.EmployeeResponseDTO,
      unknown
    >
  | QueryObserverLoadingResult<Components.Schemas.EmployeeResponseDTO, unknown>;

export function useTalentProfile({
  talentId,
}: {
  talentId?: string;
}): ResultQueryType;

export function useTalentProfile({
  applicationId,
}: {
  applicationId: string;
}): ResultQueryType;

export function useTalentProfile(
  params: { applicationId: string } | { talentId?: string }
) {
  const { accessToken } = useAccessToken();

  const fetchEmployeeProfile = useCallback(
    async () =>
      isByApplicationId(params)
        ? getEmployeeProfileByApplicationId(
            params.applicationId,
            accessToken.accessToken
          )
        : getEmployeeProfile(params.talentId, accessToken.accessToken),
    [accessToken.accessToken, params]
  );

  return useQuery<Components.Schemas.EmployeeResponseDTO>({
    queryKey: ['employee-profile', params],
    queryFn: fetchEmployeeProfile,
    enabled: isByApplicationId(params) ? true : params.talentId != null,
  });
}

export function useAllTalents(searchTerm?: string) {
  const { accessToken } = useAccessToken();
  const { isHrManager, companyId } = useUserIdentity() as AuthenticatedIdentity;

  const fetchAllEmployees = useCallback(async () => {
    const params = {
      searchTerm,
      size: 100,
      page: 0,
      includeLive: true,
      ...(isHrManager && { companyId }),
    };

    return getAllEmployees(params, accessToken.accessToken);
  }, [accessToken.accessToken, companyId, isHrManager, searchTerm]);

  return useQuery({
    queryKey: ['employees', searchTerm],
    queryFn: fetchAllEmployees,
  });
}
