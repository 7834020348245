import React from 'react';

import { en } from 'localization';
import { OffboardingFlow } from 'networking/offboarding/offboarding-client';

import { OptionCard } from './option-card';
import {
  CardsContainer,
  TerminationContainer,
  TerminationDescription,
  TerminationHeader,
} from './option-card/termination-options.styled';

const {
  terminationOptions: {
    terminationHeader,
    terminationDescription,
    fixedTermCardTitle,
    normalTerminationTitle,
  },
} = en;

export const TerminationOptions: React.FC<{
  flows: OffboardingFlow[];
  country: string;
}> = ({ flows, country }) => (
  <TerminationContainer>
    <TerminationHeader data-testid="Termination-option-page-header">
      {terminationHeader}
    </TerminationHeader>

    <TerminationDescription data-testid="Termination-option-page-Description">
      {terminationDescription}
    </TerminationDescription>

    <CardsContainer>
      {flows.map((flow: OffboardingFlow) => (
        <OptionCard
          title={
            flow.flowType === 'TERMINATION'
              ? normalTerminationTitle
              : fixedTermCardTitle
          }
          data-testid={`${flow.flowType}-card`}
          key={flow.flowType}
          flow={flow}
          country={country}
        />
      ))}
    </CardsContainer>
  </TerminationContainer>
);
