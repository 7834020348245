import React, { useCallback, useState } from 'react';

import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { useParams } from 'react-router-dom';
import { Modal, palette, ModalButtonsFooter } from 'workmotion-design-system';

import ConfirmationModal from 'app/confirmation-modal';
import { en } from 'localization';
import { useUpdateOffboardingStatus } from 'networking/offboarding/offboarding-mutations';

export interface ModalPopupProps {
  isOpen: boolean;
  closeModal: () => void;
  talentName?: string;
}

const OffboardingPopup: React.FC<ModalPopupProps> = ({
  isOpen,
  closeModal,
  talentName,
}) => {
  const {
    offBoarding: {
      deleteConfirmationPopup: {
        title,
        descriptionMessage,
        primaryButton,
        secondryButton,
      },
    },
  } = en;

  const { offboardingId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [confirmationPopupIsOpen, setConfirmationPopupIsOpen] =
    useState<boolean>(false);

  const { mutate } = useUpdateOffboardingStatus(offboardingId);

  const updateRequestStatus = useCallback(() => {
    setIsSubmitting(true);

    mutate(null, {
      onSuccess: () => {
        closeModal();
        setConfirmationPopupIsOpen(true);
      },
      onSettled: () => {
        setIsSubmitting(false);
      },
    });
  }, [closeModal, mutate]);

  return (
    <>
      <Modal
        closeModal={closeModal}
        addCloseIcon={false}
        defaultContent={{
          icon: (
            <PersonRemoveIcon
              sx={{
                color: palette.semantic.sunsetOrange,
                fontSize: '1.875rem',
              }}
            />
          ),
          text: (
            <div style={{ wordBreak: 'normal' }}>
              {descriptionMessage(talentName)}
            </div>
          ),
          title: title,
        }}
        footer={
          <ModalButtonsFooter
            buttonsInfo={[
              {
                btnType: 'secondaryGrey',
                onClick: closeModal,
                text: secondryButton,
                dataCy: 'close-btn',
              },
              {
                btnType: 'error',
                text: primaryButton,
                type: 'button',
                onClick: updateRequestStatus,
                disabled: isSubmitting,
                dataCy: 'offboard-btn',
              },
            ]}
            placement="space-between"
          />
        }
        type="warning"
        isOpen={isOpen}
      />

      <ConfirmationModal
        isOpen={confirmationPopupIsOpen}
        closeModal={() => setConfirmationPopupIsOpen(false)}
        talentName={talentName}
      />
    </>
  );
};

export default OffboardingPopup;
