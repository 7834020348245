import { useCallback } from 'react';

// eslint-disable-next-line import/named
import { UserClaims } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import * as Sentry from '@sentry/react';
import { addBreadcrumb } from '@sentry/react';
import { UserInfo } from '@workmotion/feature-flags-library/dist/types';

/**
 * This file should be extracted to the separate library in order to unify the access to the userIdentity.
 * This library should decorate the okta functionality plus shortcuts for easy access to the user roles.
 * The further steps should be discussed in the RFC format.
 *
 * This file is an enhanced version of the useUserIdentity.ts file from the onboarding-frontend repository.
 */
type UserGroup =
  | 'Everyone'
  | 'Candidates'
  | 'CompanyHrManagers'
  | 'Administrators'
  | 'Operators'
  | 'SuperAdmins'
  | 'Employees'
  | 'LineManagers'
  | 'InternalUsers'
  | 'Interns'
  | 'AddClient'
  | 'ViewClient'
  | 'UpdateClient'
  | 'DeleteClient'
  | 'AddTalent'
  | 'ViewTalent'
  | 'UpdateTalent'
  | 'DeleteTalent';

export interface WMUserClaims extends UserClaims {
  groups: UserGroup[];
  userType: 'internal';
  department: string;
  headers: Record<string, string>;
  /**
   * For HR only
   */
  companyId?: string;
}

export type AuthenticatedIdentity = {
  isAuthenticated: true;
  isAdmin: boolean;
  /**
   * @deprecated isClient is the same as isHrManager
   */
  isClient: boolean;
  isEmployee: boolean;
  isHrManager: boolean;
  isLineManager: boolean;
  isExternalLineManager: boolean;
  groups: UserGroup[];
  name: string;
  email: string;
  companyId?: string;
  featureFlagsIdentity: UserInfo;
};

export type UnAuthenticatedIdentity = {
  isAuthenticated: false;
};

export type UserIdentityDetails =
  | AuthenticatedIdentity
  | UnAuthenticatedIdentity;

const isHrManager = (groups: UserGroup[]) =>
  groups.includes('CompanyHrManagers');

const isEmployee = (groups: UserGroup[]) =>
  groups.includes('Employees') || groups.includes('Candidates');

const isAdmin = (groups: UserGroup[]): boolean =>
  groups.includes('Administrators') ||
  groups.includes('SuperAdmins') ||
  groups.includes('Operators');

const isLineManager = (groups: UserGroup[]): boolean =>
  groups.includes('LineManagers');

const isExternalLineManger = (groups: UserGroup[]): boolean =>
  groups.length == 2 &&
  groups.includes('Everyone') &&
  groups.includes('LineManagers');

const buildIdentity = (user: WMUserClaims): AuthenticatedIdentity => ({
  isAuthenticated: true,
  isAdmin: isAdmin(user.groups),
  isEmployee: isEmployee(user.groups),
  isHrManager: isHrManager(user.groups),
  isLineManager: isLineManager(user.groups),
  isExternalLineManager: isExternalLineManger(user.groups),
  isClient: isHrManager(user.groups),
  email: user.email,
  groups: user.groups,
  name: user.name,
  companyId: user.companyId,
  featureFlagsIdentity: {
    uid: user.uid,
    sub: user.sub,
    groups: user.groups,
    firstname: user.firstname,
    lastname: user.lastName,
  } as UserInfo,
});

export function useUserIdentity(): UserIdentityDetails {
  const { authState } = useOktaAuth();

  const loadUserInfo = useCallback(() => {
    const user = authState?.accessToken?.claims as WMUserClaims;
    addBreadcrumb({ message: 'User is changed', data: { user } });

    if (user == null) {
      Sentry.setUser(null);

      return { isAuthenticated: false } as UnAuthenticatedIdentity;
    }

    Sentry.setUser({
      ...user,
      email: user.email,
      username: user.preferred_username,
    });

    return buildIdentity(user);
  }, [authState?.accessToken?.claims]);

  return loadUserInfo();
}
